import * as React from 'react';
import { FC } from 'react';

interface languageList {
  localizations: any;
  megamenuvisible: boolean;
  pageid: number;
}

const MainMenuLanguageSelector: FC<languageList> = (props) => {
  const [localizationsSelectorVisible, setLocalizationsSelectorVisible] =
    React.useState(false);
  const activeLocalization = props.localizations.find(
    (localization: { active: boolean }) => localization.active
  );

  const checkPage = async (language: string) => {
    const url = `/api/navigation/geturlforlanguage/${props.pageid}/${language}`;

    try {
      const response = await fetch(url);
      const data = response;
      return (await data.text()).toString();
    } catch (error) {
      console.log(error);
    }
  };

  const handleLangugageChange = async (language: string) => {
    await checkPage(language).then((path) => {
      window.location.href = path ? path.toString() : '/';
    });
  };

  return (
    <>
      <div className="flex flex-col">
        <button
          className=" text-base leading-4 font-medium flex items-center gap-1 group"
          onClick={() =>
            setLocalizationsSelectorVisible(!localizationsSelectorVisible)
          }
        >
          <span className="uppercase group-hover:underline">
            {activeLocalization.code}
          </span>
          <span
            className={`material-symbols-outlined transition-transform duration-500 ${
              localizationsSelectorVisible ? 'rotate-180' : 'rotate-0'
            }`}
            aria-hidden="true"
          >
            keyboard_arrow_down
          </span>

          <span
            className={`material-symbols-outlined transition-transform duration-500 ${
              localizationsSelectorVisible ? 'rotate-180' : 'rotate-0'
            }`}
            aria-hidden="true"
          >
            globe
          </span>
        </button>
        {localizationsSelectorVisible ? (
          <div className="relative">
            <div
              className={`absolute top-2 left-0 border  p-4 z-50 ${
                props.megamenuvisible
                  ? 'bg-black-light border-gray-40'
                  : 'bg-white border-gray-80'
              } `}
            >
              <ul className="flex flex-col gap-2">
                {props.localizations.map(
                  (
                    item: { code: string; name: string },
                    index: React.Key | null | undefined
                  ) => {
                    return (
                      <li key={index}>
                        <button
                          type="button"
                          className="capitalize hover:underline focus:outline"
                          onClick={() => handleLangugageChange(item.code)}
                        >
                          {item.name}
                        </button>
                      </li>
                    );
                  }
                )}
              </ul>
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
    </>
  );
};
export default MainMenuLanguageSelector;
