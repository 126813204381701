import * as React from 'react';
import { FC } from 'react';

interface pageType {
  pageType: string;
}
const SearchResultIcon: FC<pageType> = (props) => {
  const [googleIcon, setGoogleIcon] = React.useState('');

  React.useEffect(() => {
    switch (props.pageType) {
      case 'ActorListPage':
        setGoogleIcon('list_alt');
        break;
      case 'ActorPage':
        setGoogleIcon('badge');
        break;
      case 'ArticlePage':
        setGoogleIcon('article');
        break;
      case 'CalendarPage':
        setGoogleIcon('event');
        break;
      case 'CalenderSearchPage':
        setGoogleIcon('calendar_month');
        break;
      case 'NewsListPage':
        setGoogleIcon('list_alt');
        break;
      case 'NewsPage':
        setGoogleIcon('news');
        break;
      case 'ProductListPage':
        setGoogleIcon('list_alt');
        break;
      case 'ProductPage':
        setGoogleIcon('package');
        break;
      case 'TopicPage':
        setGoogleIcon('tag');
        break;
    }
  }, []);

  return (
    <span className="material-symbols-outlined w-6 tablet:-ml-7">
      {googleIcon}
    </span>
  );
};
export default SearchResultIcon;
