// import ReactTest from '@/ReactTest/Scripts/ReactTest';
import ActorList from '@/Actor/Scripts/ActorList';
import ArticleHero from '@/Article/Scripts/ArticleHero';
import Breadcrumb from '@/Global/Scripts/Breadcrumb/Breadcrumb';
import MainMenu from '@/Global/Scripts/MainMenu/MainMenu';
import NewsListBlock from '@/News/Scripts/NewsListBlock';
import NewsListPage from '@/News/Scripts/NewsListPage';
import ImageSlider from '@/Product/Scripts/ImageSlider';
import ImageCarousel from '@/Global/Scripts/ImageCarousel/ImageCarousel';
import ProductListing from '@/Product/Scripts/ProductListing';
import ProductCalendarlist from '@/Product/Scripts/ProductCalendarlist';
import SearchPage from '@/Search/Scripts/SearchPage';
import Accordion from '@/TextEditor/Scripts/Accordion/Accordion';
import CopyUrlButton from '@/TextEditor/Scripts/CopyUrlButton/CopyUrlButton';
import ProductListBlock from '@/Product/Scripts/ProductListBlock';
import CalendarSearchPage from '@/Calendar/Scripts/CalendarSearchPage';
import CalendarListBlock from '@/Calendar/Scripts/CalendarListBlock';
import CalendarPageTimeSpan from '@/Calendar/Scripts/CalendarPageTimeSpan';

export default {
  Accordion,
  ActorList,
  ArticleHero,
  Breadcrumb,
  CopyUrlButton,
  ImageCarousel,
  ImageSlider,
  MainMenu,
  NewsListBlock,
  NewsListPage,
  ProductCalendarlist,
  ProductListing,
  ProductListBlock,
  SearchPage,
  CalendarSearchPage,
  CalendarListBlock,
  CalendarPageTimeSpan,
};
