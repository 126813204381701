import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';
interface ActorPageLink {
  title: string;
  text: string;
  url: string;
}

interface Images {
  imageUrl: string;
  isImageshop: boolean;
  dimensionId: string;
}
interface EventProp {
  event: {
    actorPageLink: ActorPageLink[];
    areaCategories: [];
    dayOfEvent: string;
    image: {
      imageUrl: string;
      imageAltText: string;
      isImageshop: boolean;
    };
    pageLink: string;
    pageTitle: string;
    timeOfEvent: string;
    repeatMonday: boolean;
    repeatTuesday: boolean;
    repeatWednesday: boolean;
    repeatThursday: boolean;
    repeatFriday: boolean;
    repeatSaturday: boolean;
    repeatSunday: boolean;
    startTime: string;
    endTime: string;
    startDate: string;
    endDate: string;
  };
}

const ProductCalendarlistEventcard: React.FC<EventProp> = (props) => {
  const { t } = useTranslation();
  const [images, setImages] = React.useState<Images[]>([]);
  const [loaded, setLoaded] = React.useState(false);

  const getAllImageSizes = async () => {
    const url = '/api/images/generatescaledimageurls';

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ImageUrl: props.event.image.imageUrl,
          IsImageShop: props.event.image.isImageshop,
          UseFocalPoint: true,
          Dimensions: [
            {
              DimensionId: '1680',
              width: 196,
              height: 160,
            },
            {
              DimensionId: '1024',
              width: 196,
              height: 160,
            },
            {
              DimensionId: '768',
              width: 943,
              height: 225,
            },

            {
              DimensionId: '640',
              width: 687,
              height: 225,
            },
            {
              DimensionId: '300', // Smallest mobile
              width: 687,
              height: 225,
            },
            {
              DimensionId: 'default',
              width: 640,
              height: 225,
            },
          ],
        }),
      });

      const data = await response.json();
      setImages(data);
      setLoaded(true);
    } catch (error) {
      console.log(error);
    }
  };

  const weekdays: string[] = [];
  let repetable = false;

  if (props.event.repeatMonday) {
    weekdays.push('monday');
    repetable = true;
  }

  if (props.event.repeatTuesday) {
    weekdays.push('tuesday');
    repetable = true;
  }

  if (props.event.repeatWednesday) {
    weekdays.push('wednesday');
    repetable = true;
  }

  if (props.event.repeatThursday) {
    weekdays.push('thursday');
    repetable = true;
  }

  if (props.event.repeatFriday) {
    weekdays.push('friday');
    repetable = true;
  }

  if (props.event.repeatSaturday) {
    weekdays.push('saturday');
    repetable = true;
  }

  if (props.event.repeatSunday) {
    weekdays.push('sunday');
    repetable = true;
  }

  const clock = (dateTimeString: string) => {
    const date = new Date(dateTimeString);
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
  };

  const day = (dateTimeString: string) => {
    const date = new Date(dateTimeString);
    const day = date.getDate();
    const monthNumber = date.getMonth();
    const year = date.getFullYear();
    const monthArray = [
      'january',
      'february',
      'march',
      'april',
      'may',
      'june',
      'july',
      'august',
      'september',
      'october',
      'november',
      'december',
    ];

    return `${day}. ${t(
      `calendar/timeanddate/${monthArray[monthNumber]}`
    )} ${year}`;
  };

  const listweekdays = (days: any) => {
    const translated: any[] = [];

    if (days && days.length > 0) {
      days.forEach((day: string) => {
        translated.push(t(`calendar/timeanddate/${day}`));
      });
    }

    if (translated.length === 0) {
      return '';
    }
    if (translated.length === 1) {
      return `${translated[0]} `;
    }
    if (translated.length === 2) {
      return `${translated[0]} ${t(`calendar/timeanddate/and`)} ${
        translated[1]
      }`;
    }
    const lastElement = translated.pop();
    return `${translated.join(', ')} ${t(
      `calendar/timeanddate/and`
    )} ${lastElement} `;
  };

  React.useEffect(() => {
    if (loaded === false) {
      getAllImageSizes();
    }
  }, []);

  return (
    <div className="bg-white rounded-md overflow-hidden flex flex-col laptop:flex-row items-stretch relative hover:shadow-[-7px_7px_0px_0px_#C9C6C2] active:shadow-[-3px_3px_0px_0px_#C9C6C2] focus-within:outline focus-within:outline-1 transition-shadow">
      <div className="p-4 flex flex-col w-full laptop:w-4/5 order-2 gap-4">
        <h3 className=" text-sm font-bold laptop:text-base mb-4 order-1">
          {props.event.pageTitle}
        </h3>

        <div className="order-3 laptop:order-2">
          {repetable && (
            <p className=" bg-white-warm text-sm py-2 px-4 my-4 rounded">
              {weekdays.length > 0 && (
                <>
                  {t('calendar/timeanddate/every')} &#32;
                  {listweekdays(weekdays)}
                </>
              )}
              {props.event.startTime && (
                <>
                  {t('calendar/timeanddate/from')} &#32;
                  {clock(props.event.startTime)} &#32;
                </>
              )}
              {props.event.startTime && props.event.endTime && (
                <>{t('calendar/timeanddate/to')} &#32;</>
              )}
              {props.event.endTime && <>{clock(props.event.endTime)} &#32;</>}
              {props.event.startDate && (
                <>
                  {t('calendar/timeanddate/from')} {day(props.event.startDate)}{' '}
                  &#32;
                </>
              )}
              {props.event.startDate && props.event.endDate && (
                <>{t('calendar/timeanddate/to')} &#32;</>
              )}
              {props.event.endDate && <>{day(props.event.endDate)} &#32;</>}
            </p>
          )}

          {!repetable && (
            <>
              {props.event.dayOfEvent !== null &&
                props.event.dayOfEvent !== '' && (
                  <p>{props.event.dayOfEvent}</p>
                )}

              {props.event.timeOfEvent !== null &&
                props.event.timeOfEvent !== '' && (
                  <p>
                    <span
                      className="material-symbols-outlined"
                      aria-hidden="true"
                    >
                      nest_clock_farsight_analog
                    </span>{' '}
                    {props.event.timeOfEvent}
                  </p>
                )}
            </>
          )}
        </div>
      </div>

      <div className="w-full laptop:w-1/5 order-1 overflow-hidden">
        {images.length > 0 && (
          <picture>
            {images.map((el) => {
              if (el.dimensionId === 'default') {
                return (
                  <img
                    src={el.imageUrl}
                    alt={props.event.image.imageAltText}
                    loading="lazy"
                    className="object-cover h-full w-auto"
                    key={uuidv4()}
                  />
                );
              } else {
                return (
                  <source
                    media={`(min-width: ${el.dimensionId}px)`}
                    srcSet={el.imageUrl}
                    key={uuidv4()}
                  />
                );
              }
            })}
          </picture>
        )}

        {images.length === 0 && (
          <div className="hidden laptop:block bg-gray-90 w-full h-full"></div>
        )}
      </div>

      <a
        href={props.event.pageLink}
        title={props.event.pageTitle}
        className="absolute inset-0"
      >
        <span className="sr-only">{props.event.pageTitle}</span>
      </a>
    </div>
  );
};

export default ProductCalendarlistEventcard;
