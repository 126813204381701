import * as React from 'react';
import { FC } from 'react';
import { AccordionModel } from './AccordionModel';

const Accordion: FC<AccordionModel> = (props) => {
  const [open, setOpen] = React.useState(false);

  const handleClick = (e: any) => {
    e.preventDefault();
    open === false ? setOpen(true) : setOpen(false);
  };
  return (
    <>
      <button
        type="button"
        onClick={handleClick}
        className="font-sans text-xl font-light mb-0 w-full text-left flex justify-between items-center group"
      >
        <span className="group-hover:underline underline-offset-2">
          {props.title}
        </span>
        <span
          className={`material-symbols-outlined transition-all duration-500 ${
            open ? 'rotate-180' : ''
          }`}
          aria-hidden="true"
        >
          expand_more
        </span>
      </button>
      <div
        className={`accordion--body text-lg accordion--wrapper ${
          open ? 'accordion--wrapper__open' : ''
        }`}
      >
        <div dangerouslySetInnerHTML={{ __html: props.bodytext }} />
      </div>
    </>
  );
};
export default Accordion;
