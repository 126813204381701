import React, { useEffect } from 'react';
interface ImageItem {
  url: string;
  alt: string;
  caption: string;
  photographer: string;
  isImageshop: boolean;
  dimensionId?: string;
  sizes: Sizes[];
}

interface ImageSliderProps {
  images: ImageItem[];
  productTitle: string;
}

interface Sizes {
  srcSet320: string;
  srcSet640: string;
  srcSet1024: string;
}

const ImageSlider: React.FC<ImageSliderProps> = (props) => {
  const ref = React.useRef<HTMLDivElement | null>(null);
  const [page, setPage] = React.useState<number>(0);

  // Handle button scroll to left
  const handleScrollLeft = () => {
    if (Number(page) > 0) {
      setPage(Number(page) - 1);
    }
  };

  // Handle button scroll to right
  const handleScrollRight = () => {
    if (Number(page) + 2 <= props.images.length) {
      setPage(Number(page) + 1);
    }
  };

  // Scroll to element
  const scrollTo = () => {
    if (ref?.current && ref?.current?.firstElementChild) {
      const elementWidth = ref?.current?.firstElementChild.clientWidth;
      ref.current.scrollLeft = elementWidth * page;
    }
  };

  // If scroll, update page state
  useEffect(() => {
    const handleChange = () => {
      if (ref?.current && ref?.current?.firstElementChild) {
        const scrollLeftPx = ref.current.scrollLeft;
        const elementWidth = ref.current.firstElementChild.clientWidth;
        setPage(Math.round(scrollLeftPx / elementWidth));
      }
    };

    if (ref?.current && ref?.current?.firstElementChild) {
      ref.current.addEventListener('scrollend', handleChange);
    }

    return () => {
      if (ref?.current && ref?.current?.firstElementChild) {
        ref.current.removeEventListener('scrollend', handleChange);
      }
    };
  }, []);

  // Listen to change in state for page
  useEffect(() => {
    scrollTo();
  }, [page]);

  return (
    <div className="container laptop:px-4 relative">
      {props.images.length > 1 && (
        <>
          <button
            type="button"
            onClick={() => {
              handleScrollLeft();
            }}
            className={`btn-slider-control prev ${
              page === 0 ? 'disabled' : ''
            }`}
            disabled={page === 0}
          >
            <span>&larr;</span>
          </button>
          <button
            type="button"
            onClick={() => {
              handleScrollRight();
            }}
            className={`btn-slider-control next ${
              page === props.images.length - 1 ? 'disabled' : ''
            }`}
            disabled={page === props.images.length - 1}
          >
            <span>&rarr;</span>
          </button>
        </>
      )}

      <div
        className="gap-4 flex flex-nowrap snap-x snap-mandatory scroll-smooth overflow-x-auto scrollbar-hidden"
        ref={ref}
      >
        {props.images.map((image: any, index: React.Key) => {
          return (
            <figure
              key={index}
              className="w-full shrink-0 snap-start scroll-mx-0"
            >
              <picture>
                <source
                  media={`(min-width: 1024px)`}
                  srcSet={image.sizes.srcSet1024}
                />
                <source
                  media={`(min-width: 640px)`}
                  srcSet={image.sizes.srcSet640}
                />
                <source
                  media={`(min-width: 320px)`}
                  srcSet={image.sizes.srcSet320}
                />
                <img
                  src={image.sizes.srcSet1024}
                  alt={image.alt}
                  className="laptop:rounded-md"
                />
              </picture>
              <figcaption className="px-4 laptop:px-0 mt-2 text-xs laptop:text-base">
                {image.caption}
              </figcaption>
            </figure>
          );
        })}
      </div>
      {props.images.length > 1 && (
        <ul className="px-4 laptop:px-0 flex items-center gap-3 mt-3">
          {props.images.map((image: any, index) => {
            return (
              <li key={index}>
                <button
                  type="button"
                  onClick={() => setPage(index)}
                  className={`border border-black rounded-full block ${
                    index === page
                      ? 'bg-black w-4 h-4 '
                      : 'w-3.5 h-3.5 bg-white'
                  }`}
                >
                  <span className="sr-only">{index}</span>
                </button>
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

export default ImageSlider;
