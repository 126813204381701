import * as React from 'react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface pageType {
  pageType: string;
}
const SearchResultLabel: FC<pageType> = (props) => {
  const { t } = useTranslation();
  const labelKey = `searchpage/resultlabel/${props.pageType}`;
  const label = t(labelKey);

  return (
    <p className="my-1 text-xs font-light">{label !== labelKey ? label : ''}</p>
  );
};
export default SearchResultLabel;
