import { ImageModel } from '@/Global/Scripts/Models/ImageModel';
import Loader from '@/ReactInit/Scripts/Loader';
import Modal from '@/ReactInit/Scripts/Modal';
import { SearchFacet } from '@/Search/Scripts/Models/SearchFacet';
import isEqual from 'lodash.isequal';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FilterButtonGroup from './FilterButtonGroup';
import ProductListingCard from './ProductListingCard';
import { v4 as uuidv4 } from 'uuid';

type FilterPill = SearchFacet & { active: boolean };

interface Product {
  url: string;
  pageTitle: string;
  image: ImageModel;
}
interface ProductListProps {
  productCategoryId: number;
  productCategoryName: string;
  initialSeasonFacets: FilterPill[];
  initialCategoryFacets: FilterPill[];
  initialProducts: Product[];
  initialMatchingProducts: number;
  sort: string;
  culture: string;
}
interface SearchFilter {
  mainCategory: number;
  categories: string[];
  season: string[];
  page: number;
  pageSize: number;
  sort: string;
}

const ProductListing: React.FC<ProductListProps> = ({
  productCategoryId,
  productCategoryName,
  initialSeasonFacets,
  initialCategoryFacets,
  initialProducts,
  initialMatchingProducts,
  sort,
  culture,
}) => {
  const { t } = useTranslation();

  // Initial search filter
  const initialSearchFilter: SearchFilter = useMemo(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const getQueryParamArray = (queryParam: string): string[] => {
      return urlParams.get(queryParam)?.split(',') ?? [];
    };
    return {
      mainCategory: productCategoryId,
      categories: getQueryParamArray('categories'),
      season: getQueryParamArray('season'),
      page: parseInt(urlParams.get('page') ?? '1'),
      pageSize: parseInt(urlParams.get('pageSize') ?? '12'),
      sort,
    };
  }, [window.location.search, productCategoryId]);

  // Search filters, facets and products
  const [searchFilter, setSearchFilter] =
    useState<SearchFilter>(initialSearchFilter);
  const [prevSearchFilter, setPrevSearchFilter] =
    useState<SearchFilter>(initialSearchFilter);
  const [seasonFacets, setSeasonFacets] =
    useState<FilterPill[]>(initialSeasonFacets);
  const [prevSeasonFacets, setPrevSeasonFacets] = useState<FilterPill[]>([]);
  const [categoryFacets, setCategoryFacets] = useState<FilterPill[]>(
    initialCategoryFacets
  );
  const [prevCategoryFacets, setPrevCategoryFacets] = useState<FilterPill[]>(
    []
  );
  const [products, setProducts] = useState<Product[]>();
  const [matchingProducts, setMatchingProducts] = useState<number>(0);

  const [triggerFetch, setTriggerFetch] = useState<boolean>(false);
  const [contentLoaded, setContentLoaded] = useState<boolean>(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 640);
  const [modalOpen, setModalOpen] = useState(false);

  let language = 'no';
  if (culture !== undefined) {
    switch (culture.toLowerCase()) {
      case 'en-gb':
        language = 'en';
        break;
      case 'en-us':
        language = 'en';
        break;
      case 'en':
        language = 'en';
        break;
      default:
        language = 'no';
    }
  }

  // Construct filter url based on current filters
  const filterUrl = useMemo(() => {
    const queryParams = new URLSearchParams({
      mainCategory: searchFilter.mainCategory.toString(),
      categories: searchFilter.categories.join(','),
      season: searchFilter.season.join(','),
      page: searchFilter.page.toString(),
      pageSize: searchFilter.pageSize.toString(),
      sort: searchFilter.sort.toString(),
      language,
    }).toString();
    return `/api/products/search?${queryParams}`;
  }, [searchFilter]);

  // Initial setup
  useEffect(() => {
    // Populate facets with active state based on searchFilter
    const populateFacets = (facets: FilterPill[], activeFilters: string[]) =>
      facets.map((facet) => ({
        ...facet,
        active: activeFilters.includes(facet.filterId),
      }));
    setSeasonFacets(populateFacets(seasonFacets, searchFilter.season));
    setCategoryFacets(populateFacets(categoryFacets, searchFilter.categories));

    const initialFetch = async () => {
      try {
        const response = await fetch(filterUrl);
        const result = await response.json();
        const pageHits = result.products as Product[];
        setProducts(
          pageHits.map((product) => ({
            url: product.url,
            pageTitle: product.pageTitle,
            image: product.image,
          }))
        );
        setMatchingProducts(result.totalMatching);
      } catch (error) {
        console.error('Initial fetch failed', error);
      } finally {
        setContentLoaded(true);
      }
    };
    // Populate product list based on search filter
    if (window.location.search) {
      initialFetch();
    } else {
      setProducts(initialProducts);
      setMatchingProducts(initialMatchingProducts);
      setContentLoaded(true);
    }
  }, [window.location.search]);

  const [showLoadMore, setShowLoadMore] = useState(
    matchingProducts > searchFilter.pageSize
  );
  useEffect(() => {
    if (matchingProducts > searchFilter.pageSize) {
      setShowLoadMore(true);
    }
  }, [matchingProducts, searchFilter.pageSize]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 640);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Fetch products based on filter changes
  useEffect(() => {
    if (triggerFetch) {
      const fetchProducts = async () => {
        try {
          const response = await fetch(filterUrl);
          const result = await response.json();
          const listProduct = result.products.map((product: Product) => ({
            url: product.url,
            pageTitle: product.pageTitle,
            image: product.image,
          }));

          /**
           * On small screens: To avoid unnecessary re-rendering of the product list view while the user is setting filters/the modal is open,
           * add search result(s) to the products array when the modal is closed.
           * On larger screens: Apply changes to the product list view immediately
           */
          if (isMobile) {
            if (!modalOpen) {
              setProducts(listProduct);
              setMatchingProducts(result.totalMatching);
            }
          } else {
            setProducts(listProduct);
            setMatchingProducts(result.totalMatching);
          }
        } catch (error) {
          console.error('Failed to fetch products:', error);
        }
      };
      fetchProducts();
      setTriggerFetch(false);
    }
  }, [triggerFetch]);

  // Handle filter change
  const handleFilterChange = useCallback(
    (filterType: string, filterId: string) => {
      setSearchFilter((currentFilter) => {
        const showAll = filterId === '';
        const currentFilterArray = currentFilter[
          filterType as keyof typeof currentFilter
        ] as string[];

        let newFilterArray: string[];
        if (showAll) {
          newFilterArray = [];
        } else if (currentFilterArray.includes(filterId)) {
          newFilterArray = currentFilterArray.filter((id) => id !== filterId);
        } else {
          newFilterArray = [...currentFilterArray, filterId];
        }

        const updateFacets = (facets: FilterPill[]) =>
          facets.map((facet) => ({
            ...facet,
            active: newFilterArray.includes(facet.filterId),
          }));

        if (filterType === 'season') {
          setSeasonFacets((currentFacets) => updateFacets(currentFacets));
        } else if (filterType === 'categories') {
          setCategoryFacets((currentFacets) => updateFacets(currentFacets));
        }

        setTriggerFetch(true);

        return {
          ...currentFilter,
          [filterType]: newFilterArray,
          page: 1,
        };
      });
    },
    []
  );

  //  Filter modal actions:
  /**
   * Opens the filter modal and saves the current filter/facet states as previous states,
   * so these can be restored if the user decide to cancel the changes/close the modal
   */
  const openFilterModal = useCallback(() => {
    setModalOpen(true);
    setPrevSearchFilter(searchFilter);
    setPrevSeasonFacets(seasonFacets);
    setPrevCategoryFacets(categoryFacets);
  }, [searchFilter, seasonFacets, categoryFacets]);

  /**
   * Trigger fetch based on new filter settings, and closes the modal.
   */
  const applyAllFilters = useCallback(() => {
    setTriggerFetch(true);
    setModalOpen(false);
  }, []);

  /**
   * Closes the filter modal and restores the filters to their previous states.
   */
  const closeFilterModal = useCallback(() => {
    setSearchFilter(prevSearchFilter);
    setSeasonFacets(prevSeasonFacets);
    setCategoryFacets(prevCategoryFacets);
    setModalOpen(false);
  }, [prevSearchFilter, prevSeasonFacets, prevCategoryFacets]);

  const filtersChanged =
    !isEqual(categoryFacets, prevCategoryFacets) ||
    !isEqual(seasonFacets, prevSeasonFacets);

  const activeFilterButtons = (facets: FilterPill[], filterType: string) => {
    return (
      facets.length &&
      facets
        .filter((facet) => facet.active)
        .map((facet) => (
          <button
            key={`${facet.parentId}-${facet.filterId}`}
            onClick={() => {
              handleFilterChange(filterType, facet.filterId);
            }}
            className="pill selected"
            aria-label={`${t('productlistpage/filters/resetfilter')}: ${
              facet.filterName
            }`}
          >
            {facet.filterName}
            <span
              className="font-semibold material-symbols-outlined"
              aria-hidden="true"
            >
              close
            </span>
          </button>
        ))
    );
  };

  const renderFilters = (filterType: string, facets: FilterPill[]) => {
    return (
      <FilterButtonGroup
        filterType={filterType}
        categoryName={productCategoryName.toLowerCase()}
        filters={facets}
        handleFilterChange={handleFilterChange}
        modalView={isMobile}
      />
    );
  };

  const resetFilters = useCallback(() => {
    // Reset SearchFilters state for both filter types
    setSearchFilter((currentFilter) => ({
      ...currentFilter,
      season: [],
      categories: [],
    }));
    // Reset active states
    setSeasonFacets((facets) =>
      facets.map((facet) => ({ ...facet, active: false }))
    );
    setCategoryFacets((facets) =>
      facets.map((facet) => ({ ...facet, active: false }))
    );
    setTriggerFetch(true);
  }, []);

  const loadMoreProducts = useCallback(() => {
    setSearchFilter((currentFilter) => ({
      ...currentFilter,
      pageSize: currentFilter.pageSize * 2,
    }));
    setTriggerFetch(true);
  }, []);

  /**
   * Update URL paramters on filter change
   *
   */

  useEffect(() => {
    updateUrl(
      searchFilter.season.toString(),
      searchFilter.categories.toString(),
      searchFilter.pageSize.toString()
    );
  }, [searchFilter]);

  const updateUrl = (season: string, categories: string, pagesize: string) => {
    // Update parameter in URL
    const url = new URL(
      location.protocol + '//' + location.host + location.pathname
    );

    if (season.length > 0) {
      url.searchParams.set('season', season);
    }

    if (categories.length > 0) {
      url.searchParams.set('categories', categories);
    }

    url.searchParams.set('pagesize', pagesize);

    history.pushState({}, '', decodeURIComponent(url.toString()));
  };

  return (
    <>
      {/* <!-- Filter section --> */}
      <section className="flex flex-col items-stretch justify-center w-full px-8 py-6 desktop:max-w-none laptop:py-7 u-wrapper">
        <div className="flex justify-end w-full gap-4 mx-auto desktop:u-wrapper tablet:flex-col tablet:justify-center">
          {isMobile ? (
            // Filters, small screens
            <div className="flex flex-col items-end w-full tablet:hidden">
              <button
                onClick={openFilterModal}
                className="flex items-center gap-2 btn-default w-fit"
              >
                <span className="material-symbols-outlined" aria-hidden="true">
                  filter_list
                </span>
                {t('productlistpage/filters/filterlist')}
              </button>
              <Modal
                isOpen={modalOpen}
                onClose={() => {
                  closeFilterModal();
                }}
                actionButton={{
                  label: t('productlistpage/filters/useselected'),
                  active: filtersChanged,
                  action: applyAllFilters,
                }}
              >
                {seasonFacets.length > 0 &&
                  renderFilters('season', seasonFacets)}
                {categoryFacets.length > 0 &&
                  renderFilters('categories', categoryFacets)}
              </Modal>

              {(seasonFacets.some((facet) => facet.active) ||
                categoryFacets.some((facet) => facet.active)) &&
                !modalOpen && (
                  <div
                    className="flex flex-wrap self-stretch gap-2"
                    aria-label={t('productlistpage/filters/currentlyselected')}
                  >
                    {activeFilterButtons(seasonFacets, 'season')}
                    {activeFilterButtons(categoryFacets, 'categories')}
                  </div>
                )}
            </div>
          ) : (
            /* Filters, bigger screens */
            <div className="flex-col hidden gap-4 tablet:flex">
              {seasonFacets &&
                seasonFacets.length > 0 &&
                renderFilters('season', seasonFacets)}
              {categoryFacets &&
                categoryFacets.length > 0 &&
                renderFilters('categories', categoryFacets)}
            </div>
          )}
        </div>
      </section>

      {/* <!-- Products --> */}
      <section
        className={`relative flex flex-col items-center self-stretch gap-6 u-wrapper 
        ${
          !!products && products.length
            ? 'pb-10 tablet:pb-14 laptop:pb-20 tablet:pt-5 laptop:pt-10  desktop:pt-11'
            : ''
        }`}
      >
        {contentLoaded && !!products ? (
          products.length ? (
            <>
              <div className="flex flex-col items-center self-stretch justify-start gap-7">
                <div className="grid self-stretch grid-cols-1 gap-4 tablet:grid-cols-2 laptop:grid-cols-3 tablet:gap-6 laptop:gap-7">
                  {products.map((product) => (
                    <ProductListingCard product={product} key={uuidv4()} />
                  ))}
                </div>
              </div>
              <div className="flex flex-col items-center gap-2.5 u-paragraph-m">
                <p>
                  {t('shared/showing')}{' '}
                  {Math.min(matchingProducts, searchFilter.pageSize)}{' '}
                  {t('shared/of')} {matchingProducts}
                </p>
                {showLoadMore && (
                  <button
                    onClick={loadMoreProducts}
                    disabled={matchingProducts <= searchFilter.pageSize}
                    className={`btn-default justify-center items-center gap-2 inline-flex ${
                      matchingProducts > searchFilter.pageSize
                        ? 'btn-default-black'
                        : ''
                    }`}
                  >
                    {t('productlistpage/loadmoreproducts')}
                  </button>
                )}
              </div>
            </>
          ) : (
            <div className="relative flex flex-col items-center self-stretch gap-4 px-6 overflow-hidden rounded-md py-11 bg-yellow">
              <svg
                width="241"
                height="211"
                viewBox="0 0 241 211"
                className="absolute -rotate-[15deg] -bottom-24 -right-28 laptop:-rotate-[105deg] laptop:-bottom-4 laptop:right-8 desktop:right-1/3 z-0 pointer-events-none"
                aria-hidden="true"
              >
                <use xlinkHref={'/svg/shapes.svg#no-products'}></use>
              </svg>
              <div className="z-10 text-center">
                <h3 className="u-h3">{t('shared/noresults')}</h3>
                <p className="u-paragraph-l">
                  {t('shared/noresultstext')}
                  {seasonFacets.length > 0 && categoryFacets.length > 0
                    ? `${'. '} ${t(
                        'productlistpage/filters/noresultsfiltertext'
                      )}`
                    : ''}
                </p>
              </div>
              {seasonFacets.length > 0 && categoryFacets.length > 0 && (
                <button
                  className="z-10 btn-default btn-default-white"
                  onClick={resetFilters}
                >
                  {t('productlistpage/filters/resetallfilters')}
                </button>
              )}
            </div>
          )
        ) : (
          <Loader />
        )}
      </section>
    </>
  );
};

export default ProductListing;
