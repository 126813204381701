import React from 'react';
import { useTranslation } from 'react-i18next';
import ProductCalendarlistEventcard from './ProductCalendarlistEventcard';

interface EventsProp {
  events: [];
}

const ProductCalendarlist: React.FC<EventsProp> = (props) => {
  const { t } = useTranslation();

  const [hiddenClass, setHiddenClass] = React.useState(true);

  return (
    <div className="bg-white-warm rounded-md p-4 laptop:p-10 w-full">
      <h2 className=" text-2xl laptop:text-3xl font-bold mb-4 laptop:mb-7">
        {t('productpage/nextevents')}
      </h2>

      <ul className="flex flex-col space-y-4">
        {props.events.map((event, index) => {
          return (
            <li
              key={index}
              className={index > 2 && hiddenClass ? 'hidden' : ''}
            >
              <ProductCalendarlistEventcard event={event} />
            </li>
          );
        })}
      </ul>

      {hiddenClass && props.events.length > 3 && (
        <div className="text-center mt-4">
          <button
            type="button"
            onClick={() => setHiddenClass(false)}
            className="btn-default btn-default-black"
          >
            {t('productpage/nexteventsmore')}
          </button>
        </div>
      )}
    </div>
  );
};

export default ProductCalendarlist;
