import * as React from 'react';
import { ArticleHeroModels, Images } from './ArticleHeroModel';
import { FC } from 'react';
import { v4 as uuidv4 } from 'uuid';
import ArticleHeroHeading from './ArticleHeroHeading';

const ArticleHero: FC<ArticleHeroModels> = (props) => {
  const [images, setImages] = React.useState<Images[]>([]);
  const [loaded, setLoaded] = React.useState(false);

  const getAllImageSizes = async () => {
    const url = '/api/images/generatescaledimageurls';

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ImageUrl: props.imageUrl,
          IsImageShop: props.isImageshop,
          UseFocalPoint: true,
          Dimensions: [
            {
              DimensionId: '1680',
              width: 1648,
              height: null,
            },
            {
              DimensionId: '1280',
              width: 1679,
              height: null,
            },
            {
              DimensionId: '1024',
              width: 1279,
              height: null,
            },
            {
              DimensionId: '640',
              width: null,
              height: 1600,
            },
            {
              DimensionId: '300', // Smallest mobile
              width: null,
              height: 900,
            },
            {
              DimensionId: 'default',
              width: 640,
              height: null,
            },
          ],
        }),
      });

      const data = await response.json();
      setImages(data);
      setLoaded(true);
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    if (loaded === false) {
      getAllImageSizes();
    }
  }, []);

  return (
    <>
      <figure className="mb-7 desktop:container desktop:px-4 relative desktop:rounded-lg desktop:h-auto">
        {images.length > 0 && (
          <picture>
            {images.map((el) => {
              if (el.dimensionId === 'default') {
                return (
                  <img
                    src={el.imageUrl}
                    alt={props.imageAltText}
                    loading="lazy"
                    className="w-auto h-[calc(100svh-60px)] tablet:h-auto tablet:w-full object-cover tablet:object-none desktop:w-full desktop:h-auto desktop:rounded-lg"
                    key={uuidv4()}
                  />
                );
              } else {
                return (
                  <source
                    media={`(min-width: ${el.dimensionId}px)`}
                    srcSet={el.imageUrl}
                    key={uuidv4()}
                  />
                );
              }
            })}
          </picture>
        )}

        <ArticleHeroHeading themeColor={props.themeColor} title={props.title} />
      </figure>
    </>
  );
};
export default ArticleHero;
